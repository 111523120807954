/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'
import CallToActions from 'elements/CallToActions'
import ExtraContent from 'elements/ExtraContent'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import ParseContent from 'shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  h2 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.ml};
    /* margin-bottom: 0; */
  }

  p {
    font-size: ${(props) => props.theme.font.size.sm};

    b,
    strong {
      color: ${(props) => props.theme.color.text.main};
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, seo, paginaOverOns },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault fields={paginaOverOns.paginaoverbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7">
            <Content content={paginaOverOns.paginaovercontent.description} />
          </div>
          <div className="col-lg-5 pt-lg-0 pt-4">
            <CallToActions actions={paginaOverOns.paginaovercallToAction} />
          </div>
        </div>
      </div>

      {paginaOverOns.paginaoverrelation && (
        <ExtraContent content={paginaOverOns.paginaoverrelation} />
      )}

      <div className="d-lg-block d-none" style={{ height: '60px' }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...AboutPageFrag
    }
  }
`

export default PageTemplate
